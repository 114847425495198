/** @format */

export const Connect = [
  {
    id: '#Home',
    name: 'Home',
  },
  {
    id: '#About',
    name: 'About Us',
  },
  {
    id: '#Portfolio',
    name: 'Portfolio',
  },
  {
    id: '#Skills',
    name: 'Skills',
  },
  {
    id: '#Contact',
    name: 'Contact',
  },
];
